import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./treeCuttingBooking.css";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Select from "react-select";
import UserLoginSignUp from "../../../components/UserLoginSignUp/UserLoginSignUp";
import AuthContext from '../../../context/AuthContext';
import Footer from "../../../components/Footer/Footer";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import SocialMediaIcons from "../../../components/SocialMediaIcons/SocialMediaIcons";
import FormPart1 from "./FormPart1";
import FormPart2 from "./FormPart2";
import FormPart3 from "./FormPart3";
import formatDate from "../../../utils/FormatDate";
import md5 from 'md5';
import { Helmet } from "react-helmet";


const TreeCuttingBooking = () => {
    let { authTokens, user } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [FormInputs, setFormInputs] = useState({
        selectedDate: "",
        startTime: "",
        startTimeLabel: "",
        address: "",
        postCode: "",
        propertyType: "",
        propertyTypeLabel: "",
        voucher: "",
        voucherDiscount: 0,
        paymentMethod: "Cash",
        paymentMethodLabel: "Cash",
        skill: "Tree Cutting",
        addon: "",
        addonHours: 0,
        totalCost: "",
        phone: "",
        noOfTrees: "",
    });
    const [availablePostCode, setAvailablePostCode] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    const notify = (message, type) => {
        if (type === "success") {
            toast.success(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
        else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    const getAllPostCodes = async () => {
        let response = await fetch('https://django.hayame.my/api/get-all-postcodes/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json();
        setAvailablePostCode(data);
        console.log(data);
    }

    useEffect(() => {
        getAllPostCodes();
        if (location.state === null) {
            navigate('/');
        }
        else {
            setFormInputs((values) => ({ ...values, ['postCode']: location.state.postcode }));
        }
    }, [])

    const validateForm1 = () => {
        if(FormInputs.noOfBoxes === ""){
            notify("Please enter the no of trees to cut", "error");
            return false;
        }
        if (FormInputs.selectedDate === "") {
            notify("Please select the start date", "error");
            return false;
        }
        if (FormInputs.startTime === "") {
            notify("Please select the start time", "error");
            return false;
        }

        let startDate = new Date(FormInputs.selectedDate);
        let startHour = parseInt(FormInputs.startTime[0] + FormInputs.startTime[1]);
        let startTimeStamp = (startDate.getTime() + (startHour * 3600000) - 28800000); // minus 28800000 to convert localtime to utc
        let currentTimeStamp = Date.now();
        if ((startTimeStamp - currentTimeStamp) < 43200000) {
            notify("Cannot Book within 12 hours", "error");
            return false;
        }

        return true;
    }

    const validateForm2 = () => {
        if (FormInputs.address === "") {
            notify("Please fill the address", "error");
            return false;
        }
        if (FormInputs.postCode === "") {
            notify("Please enter the postcode", "error");
            return false;
        }
        if (FormInputs.propertyType === "") {
            notify("Please select the Property Type", "error");
            return false;
        }

        let selectedPostCode = parseInt(FormInputs.postCode);
        if (availablePostCode.includes(selectedPostCode) === false) {
            notify("Currently we do not provide Services at this Location", "error");
            return false;
        }
        return true;
    }

    const validateForm3 = () => {
        if (authTokens === null) {
            notify("Please login before booking", "error");
            return false;
        }
        return true;
    }

    const bookTreeCutting = async () => {
        let response = await fetch('https://django.hayame.my/api/book-tree-cutting/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens,
            },
            body: JSON.stringify({
                'start_date': FormInputs.selectedDate,
                'start_time': FormInputs.startTime,
                'address': FormInputs.address,
                'postcode': FormInputs.postCode,
                'property_type': FormInputs.propertyType,
                'voucher': FormInputs.voucher,
                'payment_method': FormInputs.paymentMethod,
                'phone': FormInputs.phone,
                'addon': FormInputs.addon,
                'addon_service_hours': FormInputs.addonHours,
                'no_of_trees_to_cut': FormInputs.noOfTrees,
            })
        })
        let data = await response.json();
        console.log(data);
        if (FormInputs.paymentMethod == "Online") {
            let md5hash = md5(FormInputs.totalCost + "hayamesolutions" + data['booking_ids'] + "9d6c2b8c9cdd591ebd27c16ca5720fe4")

            let url = "https://pay.merchant.razer.com/RMS/pay/hayamesolutions?amount=" + FormInputs.totalCost + "&orderid=" + data['booking_ids'] + "&bill_name=" + user['first_name'] + " " + user['last_name'] + "&bill_email=" + user['email'] + "&country=MY&vcode=" + md5hash;

            window.location.href = url;
        }
        else {
            if (data['success']) {
                notify(data['response'], "success");
                navigate('/booking-history');
            }
        }
    }

    const ChangeForm = () => {

        if (page === 0) {
            if (validateForm1()) {
                setPage(1);
            }
        }
        else if (page == 1) {
            if (validateForm2()) {
                setPage(2);
            }
        }
        else {
            if (validateForm3()) {
                // call api to save booking
                bookTreeCutting();
                // redirect to booking history
            }
        }

    };

    const prevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
        else{
            navigate('/');
        }
    }

    return (
        <div>
            <Helmet>
                <title>Book Tree Cutters - Hayame</title>
                <link rel="canonical" href="/book-tree-cutting" />
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar />

            <div className="container my-5">
                <div className="row m-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 booking-form-container">
                        <div className="mb-4 back-button-div" onClick={prevPage}>
                            <svg width="26px" height="26px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill="#58BBA6" /></svg>
                        </div>
                        <div className="row m-0 mb-5">
                            <div className="stepper-div-container">
                                <div className="stepper-div">
                                    <div className={page >= 0 ? "stepper-fill" : ""}></div>
                                </div>
                                <div className="stepper-div">
                                    <div className={page >= 1 ? "stepper-fill" : ""}></div>
                                </div>
                                <div className="stepper-div">
                                    <div className={page >= 2 ? "stepper-fill" : ""}></div>
                                </div>
                            </div>
                            <div className="stepper-name-container">
                                <div className="stepper-name">Booking Details</div>
                                <div className="stepper-name">Address</div>
                                <div className="stepper-name">Payment</div>
                            </div>
                        </div>

                        <div>
                            {page === 0 ? (
                                <FormPart1 FormInputs={FormInputs} setFormInputs={setFormInputs} />
                            ) : page === 1 ? (
                                <FormPart2 FormInputs={FormInputs} setFormInputs={setFormInputs} />
                            ) : (
                                <FormPart3 FormInputs={FormInputs} setFormInputs={setFormInputs} />
                            )}
                        </div>
                        <div className="row m-0 justify-content-end">
                            <button className="booking-form-btn" onClick={ChangeForm}>
                                {page <= 1 ? "Next" : "Pay Now"}
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
            <SocialMediaIcons />

        </div>
    );
};

export default TreeCuttingBooking;
