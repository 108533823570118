import React, { useState, useEffect } from 'react';
import "./caringWheelsLanding.css";
import Navbar from '../../../components/Navbar/Navbar';
import CustomerReviews from '../../../components/CustomerReviews/CustomerReviews';
import Footer from '../../../components/Footer/Footer';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { HayameCaringWheelsService, WheelChair, ElderlyCareTakerWithWheelChair, WheelchairSecureTransportation, CaringWheels, CheckCircle } from '../../../assets';
import Slider from "react-slick";
import { Helmet } from 'react-helmet';

const CaringWheelsLanding = () => {
    const [postcode, setPostcode] = useState();
    const [availablePostCode, setAvailablePostCode] = useState();

    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    cssEase: "linear",
                }
            }
        ]
    };

    const notify = (message, type) => {
        if (type === "success") {
            toast.success(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
        else {
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    const getAllPostCodes = async () => {
        let response = await fetch('https://django.hayame.my/api/get-all-postcodes/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json();
        setAvailablePostCode(data);
    }

    useEffect(() => {
        getAllPostCodes();
    }, []);


    const validateForm = () => {
        let selectedPostCode = parseInt(postcode);
        if (availablePostCode.includes(selectedPostCode) === false) {
            notify("Currently we do not provide Services at this Location", "error");
            return false;
        }
        return true;
    }

    const OpenCloseFAQ = (e) => {
        let accordionContent = e.target.nextElementSibling;
        let headerElement = e.target;

        // if clicked on cross icon, get the parent's sibling element
        if (accordionContent == null) {
            accordionContent = e.target.parentElement.nextElementSibling;
            headerElement = e.target.parentElement;
        }

        headerElement.classList.toggle('active');

        if (headerElement.classList.contains('active')) {
            accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
        } else {
            accordionContent.style.maxHeight = 0;
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            navigate('/book-caring-wheels', { state: { postcode: postcode } });
        }

        // let response = await fetch('https://django.hayame.my/api/send-query/', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         'first_name': "Rohan",
        //         'last_name': "Raut",
        //         'email': "rohanraut124@gmail.com",
        //         'subject': "User Interaction",
        //         'message': "User searched for: " + postcode
        //     })
        // })
        // let data = await response.json();

    }


    return (
        <div>
            <Helmet>
                <title>Elderly Care Service Company in Malaysia - Hayame</title>
                <meta name="description" content="Hayame offers elderly care service in malaysia with compassionate caregiving for the elderly, expectant mothers, parents, and those with different abilities." />
                <link rel="canonical" href="/elderly-care-malaysia" />
            </Helmet>

            <Navbar />
            <div className="row m-0 caring-wheels-landing-header-section">
                <div className="caring-wheels-service-landing-header-img"></div>
                <div className="caring-wheels-landing-form-section">
                    <h1 className="caring-wheels-service-landing-h1">Elderly Care in Malaysia - <br /> Your Trusted Companion for Daily Support and Care</h1>

                    <p className="color-white mb-4">At Hayame Elderly Care Service, we provide compassionate and reliable services to ensure elderly, expectant mothers, parents with young children, and individuals with different abilities reach their destinations safely and comfortably, whether for appointments, errands, or social activities.</p>

                    <form onSubmit={handleSubmit}>
                        <div className="row m-0">
                            <div className="col-10 col-sm-10 col-md-4 col-lg-5 mb-3 px-0">
                                <input
                                    type="text"
                                    className="input-field"
                                    name="postCode"
                                    placeholder="Postal Code"
                                    onChange={(e) => setPostcode(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-5 col-sm-5 col-md-1 col-lg-2">
                                <button className="booking-form-btn" type="submit">
                                    Next
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>



            <div className="row mx-0 justify-content-center caring-wheels-landing-cover-section">
                <h2 className="caring-wheels-landing-h2 mb-4">What We Offer</h2>
                <div className="row mx-0 mb-3 justify-content-center">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-9">
                        <p>Life's demands, such as work, travel, or other obligations, may prevent us from always being there for our loved ones. But their care and well-being remain essential, and sometimes finding trusted support outside the family is necessary.</p>
                        <p>Hayame Elderly Care is dedicated to providing compassionate companionship and caregiving services for the elderly, expectant mothers, parents with young children, and individuals with different abilities. Your caregiver or teman — carefully selected companion.</p>
                    </div>

                </div>
                <div className="row mx-0 justify-content-evenly">
                    <div className="col-11 col-sm-11 col-md-5 col-lg-3 caring-wheels-landing-cover-card">
                        <img src={ElderlyCareTakerWithWheelChair} alt="Clean Living Room" />
                        <h3>Professional and friendly staff to assist in wheeling elderly patients.</h3>
                    </div>
                    <div className="col-11 col-sm-11 col-md-5 col-lg-3 caring-wheels-landing-cover-card">
                        <img src={WheelChair} alt="Clean Bedroom" />
                        <h3>We provide the wheelchair—no need to worry about bringing your own.</h3>
                    </div>
                    <div className="col-11 col-sm-11 col-md-5 col-lg-3 caring-wheels-landing-cover-card">
                        <img src={WheelchairSecureTransportation} alt="Clean Kitchen" />
                        <h3>Safe and secure transportation to and from hospitals or clinics.</h3>
                    </div>
                </div>
            </div>


            <div className="row mx-0 justify-content-evenly caring-wheels-landing-process-section">
                <h2 className="caring-wheels-landing-h2 mb-5">Easy 3 Step Elderly Care Service Booking</h2>
                <div className="col-10 col-sm-10 col-md-3 col-lg-3 mb-3 caring-wheels-landing-process-card p-4">
                    <div className="text-center mb-1">
                        Step 1
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#58BBA6"><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" /></svg>
                        <h3 className="caring-wheels-landing-h3 px-1">Booking Details</h3>
                    </div>
                    <div>
                        Select the date, time and number of days for the service as per your convenience with the number of caretakers required.
                    </div>
                </div>

                <div className="col-10 col-sm-10 col-md-3 col-lg-3 mb-3 caring-wheels-landing-process-card p-4">
                    <div className="text-center mb-1">
                        Step 2
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#58BBA6"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                        <h3 className="caring-wheels-landing-h3 px-1">Address</h3>
                    </div>
                    <div>
                        Pick your service location and make the payment.
                    </div>
                </div>

                <div className="col-10 col-sm-10 col-md-3 col-lg-3 mb-3 caring-wheels-landing-process-card p-4">
                    <div className="text-center mb-1">
                        Step 3
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#58BBA6"><path d="M480-80q-73-9-145-39.5T206.5-207Q150-264 115-351T80-560v-40h40q51 0 105 13t101 39q12-86 54.5-176.5T480-880q57 65 99.5 155.5T634-548q47-26 101-39t105-13h40v40q0 122-35 209t-91.5 144q-56.5 57-128 87.5T480-80Zm-2-82q-11-166-98.5-251T162-518q11 171 101.5 255T478-162Zm2-254q15-22 36.5-45.5T558-502q-2-57-22.5-119T480-742q-35 59-55.5 121T402-502q20 17 42 40.5t36 45.5Zm78 236q37-12 77-35t74.5-62.5q34.5-39.5 59-98.5T798-518q-94 14-165 62.5T524-332q12 32 20.5 70t13.5 82Zm-78-236Zm78 236Zm-80 18Zm46-170ZM480-80Z" /></svg>
                        <h3 className="caring-wheels-landing-h3 px-1">Service Delivery</h3>
                    </div>
                    <div>Sit back and relax, our care takers will arrive at the scheduled time.</div>
                </div>
            </div>


            <div className="row mx-0 justify-content-center caring-wheels-landing-benefits-section">
                <h2 className="caring-wheels-landing-h2 mb-5 color-34AFAA">Why Choose Hayame for Caregiver Service</h2>
                <div className="col-11 col-sm-11 col-md-12 col-lg-5 mb-5">
                    <img src={CaringWheels} alt="" width="100%" />
                </div>
                <div className="col-11 col-sm-11 col-md-12 col-lg-7">
                    <div className="row justify-content-center mx-0">
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Affordable Prices</h3>
                            <p>We offer the services at the best prices starting from RM 30 per hour without compromising the quality.</p>
                        </div>
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Experienced Care Takers</h3>
                            <p>Thoroughly vetted and trained in assisting elderly patients with care and respect.</p>

                        </div>
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Flexible Time</h3>
                            <p>Morning, afternoon, or evening appointments — we're here to help whenever you need us.</p>
                        </div>
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Satisfaction Guarantee</h3>
                            <p>Peace of mind knowing your loved ones are in good hands.</p>
                        </div>
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Convenience</h3>
                            <p>Easy booking process through Hayame's platform with reliable and punctual service.</p>
                        </div>
                        <div className="col-11 col-sm-11 col-md-6 col-lg-6 mb-3 px-3">
                            <h3 className="caring-wheels-landing-h3 mb-2 color-34AFAA">Great Customer Support</h3>
                            <p>Easy to reach out to us through Whatsapp, Email or by filling the <Link to="/contact-us" className="no-decoration">Contact Us</Link> form.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-0 justify-content-evenly">
                <h2 className="caring-wheels-landing-h2 mb-4 text-center">Where We Provide Our Caregiver Service in Malaysia</h2>
                <div className="col-12 col-sm-6 col-md-5 col-lg-3 text-center">
                    <h3 className="caring-wheels-landing-h3 mb-3">Kuala Lumpur</h3>
                    <ul className="list-style-type-none">
                        <li>Bukit Bintang</li>
                        <li>Chow Kit</li>
                        <li>Sentul</li>
                        <li>Brickfields (Little India)</li>
                        <li>Bangsar</li>
                        <li>Kepong</li>
                        <li>Setapak</li>
                        <li>Taman Tun Dr Ismail (TTDI)</li>
                        <li>Mont Kiara</li>
                        <li>Sri Hartamas</li>
                        <li>Cheras</li>
                        <li>Seputeh</li>
                        <li>Kampung Baru</li>
                        <li>Ampang Hilir</li>
                        <li>Titiwangsa</li>
                        <li>Damansara Heights</li>
                    </ul>
                </div>
                <div className="col-12 col-sm-6 col-md-5 col-lg-3 text-center">
                    <h3 className="caring-wheels-landing-h3 mb-3">Selangor</h3>
                    <ul className="list-style-type-none">
                        <li>Shah Alam</li>
                        <li>Petaling Jaya</li>
                        <li>Subang Jaya</li>
                        <li>Klang</li>
                        <li>Puchong</li>
                        <li>Selayang</li>
                        <li>Rawang</li>
                        <li>Seri Kembangan</li>
                        <li>Ampang</li>
                        <li>Kajang</li>
                        <li>Bangi</li>
                        <li>Cyberjaya</li>
                        <li>Putrajaya</li>
                        <li>Sungai Buloh</li>
                        <li>Setia Alam</li>
                        <li>Port Klang</li>
                        <li>Banting</li>
                        <li>Sepang</li>
                        <li>Kota Kemuning</li>
                    </ul>
                </div>
            </div>


            <div className="row mx-0 justify-content-center caring-wheels-landing-faq-section">
                <h2 className="caring-wheels-landing-h2 mb-5">Frequently Asked Questions</h2>
                <div className="col-11 col-sm-11 col-md-10 col-lg-8">
                    <div className="accordion-container">
                        <div className="accordion-item">
                            <button className="accordion-header" onClick={OpenCloseFAQ}>
                                How do I book your Elderly Care service? <span className="icon">+</span>
                            </button>
                            <div className="accordion-content">
                                <p>Booking a service is easy. Simply visit our website, select caring wheels service, choose a convenient date and time, and provide your contact details. Our team will confirm your booking and ensure a care taker is assigned to your request. </p>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <button className="accordion-header" onClick={OpenCloseFAQ}>
                                What if I am not satisfied with the service provided?  <span className="icon">+</span>
                            </button>
                            <div className="accordion-content">
                                <p>Customer satisfaction is our top priority. If you are not satisfied with the service, please contact our customer support within 24 hours of the service. We will address your concerns and take appropriate actions to resolve the issue. </p>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <button className="accordion-header" onClick={OpenCloseFAQ}>
                                Can I reschedule or cancel a booking?<span className="icon">+</span>
                            </button>
                            <div className="accordion-content">
                                <p>Yes, you can reschedule or cancel a booking. Please do so at least 24 hours before the scheduled service time to avoid any cancellation fees. You can manage your bookings through our website or by contacting customer support.</p>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <button className="accordion-header" onClick={OpenCloseFAQ}>
                                How do I pay for the services?<span className="icon">+</span>
                            </button>
                            <div className="accordion-content">
                                <p>Payment can be made online through our secure payment gateway using credit/debit cards or e-wallets. Alternatively, you can choose to pay in cash after the service is completed.</p>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <button className="accordion-header" onClick={OpenCloseFAQ}>
                                Do you offer any discounts or promotions?  <span className="icon">+</span>
                            </button>
                            <div className="accordion-content">
                                <p>Yes, we regularly offer discounts and promotions. Keep an eye on our website and social media pages for the latest deals. You can also subscribe to our newsletter to receive updates on special offers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />

        </div>
    )
}

export default CaringWheelsLanding