import { DateRangePicker, Stack } from 'rsuite';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import React, { useEffect, useState, useContext } from 'react';
import "./dateRangeFilter.css";
// import "rsuite/dist/rsuite.css";
import "rsuite/DateRangePicker/styles/index.css"



const predefinedRanges = [
    {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left'
    },
    {
        label: 'Tomorrow',
        value: [addDays(new Date(), 1), addDays(new Date(), 1)],
        placement: 'left'
    },
    {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
    },
    {
        label: 'Last 7 Days',
        value: [addDays(new Date(), -7), new Date()],
        placement: 'left'
    },
    {
        label: 'Last 30 Days',
        value: [addDays(new Date(), -30), new Date()],
        placement: 'left'
    }
];
const DateRangeFilter = ({ dateRange, setDateRange }) => (
    <>
        <DateRangePicker
            showOneCalendar ranges={predefinedRanges}
            calendarSnapping={true}
            value={dateRange}
            onChange={
                (e) => setDateRange(e)
            }
        />
    </>
);

export default DateRangeFilter;