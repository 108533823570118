import React, { useEffect, useState, useContext } from 'react'
import { Space, Table, Tag } from 'antd';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import AuthContext from '../../context/AuthContext';
import DataTable, { SortOrder } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import formatDate from "./../../utils/FormatDate";
import formatTime from "./../../utils/FormatTime";
import { Helmet } from 'react-helmet';
import Select from "react-select";
import DateRangeFilter from '../../components/DateRangeFilter/DateRangeFilter';
import addDays from 'date-fns/addDays';

const ReportBookingList = () => {
    const [tableData, setTableData] = useState();
    const [managerOptions, setManagerOptions] = useState();
    const [selectedManager, setSelectedManager] = useState({ value: "", label: "" });
    const [dateRange, setDateRange] = useState([]);

    let { authTokens } = useContext(AuthContext);

    const navigate = useNavigate();

    const sortByDate = (rowA, rowB) => {
        const a = rowA.start_date;
        const b = rowB.start_date;

        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    }

    const columns = [
        {
            name: 'Booking ID',
            selector: row => row.booking_id,
        },
        {
            name: 'Address',
            selector: row => <a href={"https://www.google.com/maps/search/" + (row.address).replaceAll(" ", "+")} target='_blank'>{row.address}</a>,
        },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
        },
        {
            name: 'Customer Phone',
            selector: row => row.customer_phone,
        },
        {
            name: 'Start Date',
            selector: row => formatDate(row.start_date),
            sortable: true,
            sortFunction: sortByDate
        },
        {
            name: 'Start Time',
            selector: row => formatTime(row.start_time),
        },
        {
            name: 'Hours',
            selector: row => row.no_of_hours,
        },
        {
            name: 'Total Cost',
            selector: row => row.total_cost,
        },
        {
            name: 'Actions',
            button: true,
            cell: (row) => <button onClick={() => navigate('/report-booking-details', { state: { skill: row.skill, bookingId: row.id } })}>Details</button>
        }
    ];


    const getTableData = async () => {
        let formatedDate = [];
        if (dateRange.length != 0) {
            let dt = dateRange[0];
            dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
            formatedDate.push(dt);
            dt = dateRange[1];
            dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
            formatedDate.push(dt);
        }
        let response = await fetch('https://django.hayame.my/api/get-completed-bookings/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens,
            },
            body: JSON.stringify({
                'manager': selectedManager.value,
                'date_range': formatedDate
            })
        })
        let data = await response.json()
        console.log(data);

        setTableData(data);
    }

    const getManagers = async () => {
        let response = await fetch('https://django.hayame.my/api/get-managers/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens,
            },
        })
        let data = await response.json()
        setManagerOptions(data);
    }

    useEffect(() => {
        getTableData();
        getManagers();
    }, []);

    useEffect(() => {
        getTableData();
    }, [selectedManager, dateRange]);

    const setTodayDateRange = () => {
        setDateRange([addDays(new Date(), 0), new Date()]);
    }

    const setTommDateRange = () => {
        setDateRange([addDays(new Date(), 1), addDays(new Date(), 1)]);
    }

    const setThisWeekDateRange = () => {
        let dt = new Date();
        let diff = dt.getDate() - dt.getDay() + (dt.getDay() === 0 ? -6 : 1);
        let startDate = new Date(dt.setDate(diff));
        setDateRange([startDate, addDays(startDate, 6)]);
    }


    return (
        <div>
            <Helmet>
                <title>Report - Hayame</title>
                <link rel="canonical" href="/report" />
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar />
            <div className="row mx-0 my-5 justify-content-center">
                <div className="col-11 col-sm-11 col-md-11 col-lg-10">
                    <div className="report-filters py-4">
                        <div className="row">
                            <div className="col-11 col-sm-11 col-md-3 col-lg-3 mb-3">
                                <label>Manager</label>
                                <Select onChange={setSelectedManager} options={managerOptions} placeholder="" required className="react-select-dropdown m-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        minHeight: 40,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#A4E2D5',
                                            primary: '#58BBA6',
                                        },
                                    })}

                                    value={selectedManager}

                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? '#A4E2D5' : 'gray',
                                            padding: '3px'
                                        }),
                                    }}

                                />
                            </div>
                            <div className="col-11 col-sm-11 col-md-3 col-lg-3 mb-4">
                                <label>Date Range</label>
                                <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="mb-2">Check booking for: </div>
                        <button className="btn btn-primary mx-1" onClick={setTodayDateRange}>Today</button>
                        <button className="btn btn-primary mx-1" onClick={setTommDateRange}>Tomorrow</button>
                        <button className="btn btn-primary mx-1" onClick={setThisWeekDateRange}>This Week</button>
                    </div>
                    <div className="report-datatable">
                        <DataTable columns={columns} data={tableData} defaultSortFieldId={5} pagination />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ReportBookingList