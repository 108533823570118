import React, { useEffect, useState, useContext } from "react";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Select from "react-select";

const FormPart1 = ({ FormInputs, setFormInputs }) => {
    const date = new Date();
    // const formatter = new
    const [startTimeOptions, setStartTimeOptions] = useState([
        {'value': '09:00', 'label': '9:00 am'},
        {'value': '10:00', 'label': '10:00 am'},
        {'value': '11:00', 'label': '11:00 am'},
        {'value': '12:00', 'label': '12:00 pm'},
        {'value': '13:00', 'label': '1:00 pm'},
        {'value': '14:00', 'label': '2:00 pm'},
        {'value': '15:00', 'label': '3:00 pm'},
        {'value': '16:00', 'label': '4:00 pm'},
        {'value': '17:00', 'label': '5:00 pm'},
        {'value': '18:00', 'label': '6:00 pm'},
        {'value': '19:00', 'label': '7:00 pm'},
        {'value': '20:00', 'label': '8:00 pm'}
    ]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs((values) => ({ ...values, [name]: value }));
    };

    const handleWorkerCount = (event) => {
        let name = event.target.name;
        let value = parseInt(event.target.value);
        if(value < 1){
            value = 1;
        }
        else if(value > 5){
            value = 5;
        }
        if(value === "NaN"){
            value = 1;
        }
        setFormInputs((values) => ({ ...values, [name]: value }));
    }

    return (
        <div>

            {/* No of Trees to Cut */}
            <div className="row form-section-row">
                <div className="form-label-bold">Number of trees to cut</div>
                <div className="col-6 p-0">
                    <input type="number" className="input-field" name="noOfTrees" onChange={handleChange} defaultValue={FormInputs.noOfBoxes} required />
                </div>
            </div>

            {/* Calendar */}
            <div className="row form-section-row" id="book-tree-cutting-date-section">
                <div className="form-label-bold">Select the Start Date</div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 p-0">
                    <DatePicker FormInputs={FormInputs} setFormInputs={setFormInputs} />
                </div>
            </div>

            {/* {Start time section} */}
            <div className="row form-section-row" id="book-tree-cutting-start-time-section">
                <div className="form-label-bold">Enter your start time here</div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0">
                    <Select
                        onChange={(e) => {
                            setFormInputs((values) => ({ ...values, ['startTime']: e.value }));
                            setFormInputs((values) => ({ ...values, ['startTimeLabel']: e.label }));
                        }}
                        options={startTimeOptions}
                        defaultValue={{ label: FormInputs.startTimeLabel, value: FormInputs.startTime }}
                        required
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            minHeight: 40,
                            colors: {
                                ...theme.colors,
                                primary25: '#A4E2D5',
                                primary: '#58BBA6',
                            },
                        })}

                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#A4E2D5' : 'gray',
                                padding: '3px'
                            }),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormPart1;